// theme constant
import axios from "axios";
import {getUserToken} from "../routes/helpers";

export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const headerBarHeight = 80;

const userToken = getUserToken();

let currRole = '';
let currUser = [];
let currUserOrg = [];
await axios.get('/api/user', {headers: {'Authorization': 'Bearer ' + userToken}})
    .then(res => {
        console.log('curr user const', res.data)
        currUser = res.data.user;
        currRole = res.data.user.roles[0].name;
        currUserOrg = res.data.organizations[0];
    })
    .catch(err => {
        console.log('get current user constant error', err);
    })
export const currentUserRole = currRole;
export const currentUserOrg = currUserOrg;
export const currentUser = currUser;
