import { useEffect } from 'react'
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {getCookie, setCookie} from "./routes/helpers";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
      const userTokenCookie = getCookie('userToken');
      console.log('userTokenCookie: ', userTokenCookie)
      const queryParameters = new URLSearchParams(window.location.hash);
      console.log('queryParameters: ', queryParameters)
      const tokenParam = queryParameters.get("access_token");
      console.log('tokenParam: ', tokenParam)
      let userToken = '';
      if (tokenParam) {
          userToken = tokenParam;
      } else if (userTokenCookie) {
          userToken = userTokenCookie;
      } else {
          userToken = '';
      }
      console.log('userToken: ', userToken)
      setCookie('userToken', userToken);
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
