// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = { IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const usersMenu = {
    id: 'users',
    title: 'Users',
    type: 'item',
    url: '',
    icon: icons.IconUsers,
    breadcrumbs: false
};

export default usersMenu;
