export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function getUserToken() {
    const userTokenCookie = getCookie('userToken');
    const queryParameters = new URLSearchParams(window.location.hash);
    const tokenParam = queryParameters.get("access_token");
    let userToken = '';
    if (tokenParam) {
        userToken = tokenParam;
    } else if (userTokenCookie) {
        userToken = userTokenCookie;
    } else {
        userToken = '';
        if(window.location.pathname != '/login') {
          window.location.assign('/login')
        }
    }

    return userToken;
}
