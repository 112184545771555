import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const UsersDashboard = Loadable(lazy(() => import('views/dashboard/Users')));
const AccountProfile = Loadable(lazy(() => import('views/account-profile')));
const AccountInfo = Loadable(lazy(() => import('views/pages/account-info')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <UsersDashboard />
        },
        {
            path: 'account-profile',
            element: <AccountProfile />
        },
        {
            path: 'account-info',
            element: <AccountInfo />
        }
    ]
};

export default MainRoutes;
