// assets
import { IconLogout } from '@tabler/icons';

// constant
const icons = { IconLogout };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const logoutMenu = {
    id: 'logout',
    title: 'Logout',
    type: 'item',
    url: 'https://account-uat.geograph.tech/user/oauth20/signout?client_id=809C9A0F-DC4B-4826-970D-12244FF8E28F&RelayState=AnyStateFromClient',
    icon: icons.IconLogout,
    breadcrumbs: false,
    external: true
};

export default logoutMenu;
