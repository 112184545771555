import usersMenu from './users';
import logoutMenu from './logout';
import accountInfoMenu from './account-info';
import {currentUserRole} from "../store/constant";
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

let items = [];
if (currentUserRole === "Admin") {
    items = [usersMenu, accountInfoMenu, logoutMenu]
} else {
    items = [usersMenu, logoutMenu]
}
const menuItems = {
    items: items
};

export default menuItems;
