// assets
import { IconInfoCircle } from '@tabler/icons';

// constant
const icons = { IconInfoCircle };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const accountInfoMenu = {
    id: 'account-info',
    title: 'Account Info',
    type: 'item',
    url: '/account-info',
    icon: icons.IconInfoCircle,
    breadcrumbs: false,
    external: false,
};

export default accountInfoMenu;
