// material-ui
import { useTheme } from '@mui/material/styles';

// import logoDark from 'assets/images/logo-dark.svg';
import logoLight from 'assets/images/logo-white.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    // const theme = useTheme();

    return <img src={logoLight} alt="Geograph G" width="100" />;
};

export default Logo;
